import { callApi } from "./utils/api";
import "./App.css";
import { useEffect, useState } from "react";

interface UserDetails {
  username: string;
}

interface Post {
  id: number;
  caption: string;
  media_type: string;
  media_url: string;
  timestamp: string;
  permalink: string;
}

interface Fb {
  id: number;
  created_time: string;
  full_picture: string;
  permalink_url: string;
  likes?: {
    data: {
      pic_small: string;
      id: string;
    }[];
    paging: {
      cursors: {
        before: string;
        after: string;
      };
    };
  };
}

interface Page {
  name: string;
  id: number;
  access_token: string;
}

function formatDate(timestamp: string): string {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedDate = `${day < 10 ? "0" + day : day}-${
    month < 10 ? "0" + month : month
  }-${year} ${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;
  return formattedDate;
}

function App() {
  const [userDetails, setUserDetails] = useState<UserDetails>({ username: "" });
  const [postDetails, setPostDetails] = useState<Post[]>([]);
  const [pageDetails, setPageDetails] = useState<Page[]>([]);
  const [fbDetails, setfbDetails] = useState<Fb[]>([]);
  const [activeTab, setActiveTab] = useState<string>("instagram");
  const [postType, setPostType] = useState<string>("today");
  const [selectedPageId, setSelectedPageId] = useState("");

  useEffect(() => {
    getPostDetails();
  }, []);

  useEffect(() => {
    getPageDetails();
  }, []);

  const getPostDetails = () => {
    callApi("GET", "post_details.php")
      .then((res) => res.data)
      .then((response) => {
        setUserDetails(response.data);
        console.log("setUserDetails", setUserDetails);
        setPostDetails(response.additionalData.data);
      });
  };

  const getPageDetails = () => {
    callApi("GET", "facebook_get_account.php")
      .then((res) => res.data)
      .then((response) => {
        setPageDetails(response.accounts.data);
        console.log("setPageDetails", pageDetails);
      });
  };

  const handlePageChange = (event: any) => {
    getAccountDetails(event.target.value);
    setSelectedPageId(event.target.value);
    console.log("event.target.value", event.target.value);
  };

  const getAccountDetails = (id: any) => {
    const selectedPage = pageDetails.find(page => page.id === id);
    if (selectedPage) {
      let req = {
        page_id: id,
        access_token: selectedPage.access_token 
      };
      callApi("POST", "facebook_get_page.php", req)
        .then((res) => res.data)
        .then((response) => {
          console.log("response", response);
          setfbDetails(response.posts.data);
          console.log("setfbDetails", fbDetails);
        });
    }
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setSelectedPageId("");
    setfbDetails([]);
  };

  const handlePostTypeChange = (type: string) => {
    setPostType(type);
  };

  const todayPosts = postDetails.filter((post) => {
    const postDate = new Date(post.timestamp);
    const today = new Date();
    return postDate.toDateString() === today.toDateString();
  });

  const todayFbPosts = fbDetails.filter((post) => {
    const postDate = new Date(post.created_time);
    const today = new Date();
    return postDate.toDateString() === today.toDateString();
  });

  return (
    <div className="App">
      <div className="app-wrapper">
        <nav className="app-header">
          <div className="name">{userDetails.username}</div>
        </nav>
        <div className="app-container">
          <div className="options">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-insta-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-insta"
                  type="button"
                  role="tab"
                  aria-controls="pills-insta"
                  aria-selected="true"
                  onClick={() => handleTabChange("instagram")}
                >
                  Instagram
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-fb-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-fb"
                  type="button"
                  role="tab"
                  aria-controls="pills-fb"
                  aria-selected="false"
                  onClick={() => handleTabChange("facebook")}
                >
                  Facebook
                </button>
              </li>
            </ul>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  onClick={() => handlePostTypeChange("today")}
                >
                  Today's Post
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => handlePostTypeChange("all")}
                >
                  All Posts
                </button>
              </li>
            </ul>
          </div>
          {activeTab === "facebook" && (
            <div className="select-page">
              <select
                className="form-select"
                aria-label="Default select example"
                value={selectedPageId}
                onChange={handlePageChange}
              >
                <option selected>Select a Facebook page</option>
                {pageDetails.map((page) => (
                  <option key={page.id} value={page.id}>
                    {page.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex={1}
            >
              {activeTab === "instagram" && (
                <table className="table table-striped table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Post</th>
                      <th scope="col">Caption</th>
                      <th scope="col">Media</th>
                      <th scope="col">Posted date</th>
                      <th scope="col">Post link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todayPosts?.map((post, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {post.media_type === "VIDEO" ? (
                            <video style={{ width: "70px" }} controls>
                              <source src={post.media_url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              style={{ width: "70px" }}
                              src={post.media_url}
                              alt=""
                            />
                          )}
                        </td>
                        <td>{post.caption}</td>
                        <td>{post.media_type}</td>
                        <td>{formatDate(post.timestamp)}</td>
                        <td>
                          <a href={post.permalink}>{post.permalink}</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {activeTab === "facebook" && (
                <table className="table table-striped table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Post</th>
                      <th scope="col">Post Likes</th>
                      <th scope="col">Posted date</th>
                      <th scope="col">Post link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todayFbPosts?.map((post, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <img
                            style={{ width: "100px" }}
                            src={post.full_picture}
                            alt=""
                          />
                        </td>
                        <td>{post.likes ? post.likes.data.length : '-'}</td>
                        <td>{formatDate(post.created_time)}</td>
                        <td>
                          <a href={post.permalink_url}>{post.permalink_url}</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabIndex={2}
            >
              {activeTab === "instagram" && (
                <table className="table table-striped table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Post</th>
                      <th scope="col">Caption</th>
                      <th scope="col">Media</th>
                      <th scope="col">Posted date</th>
                      <th scope="col">Post link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postDetails?.map((post, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {post.media_type === "VIDEO" ? (
                            <video style={{ width: "70px" }} controls>
                              <source src={post.media_url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              style={{ width: "70px" }}
                              src={post.media_url}
                              alt=""
                            />
                          )}
                        </td>
                        <td>{post.caption}</td>
                        <td>{post.media_type}</td>
                        <td>{formatDate(post.timestamp)}</td>
                        <td>
                          <a href={post.permalink}>{post.permalink}</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {activeTab === "facebook" && (
                <table className="table table-striped table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Post</th>
                      <th scope="col">Post Likes</th>
                      <th scope="col">Posted date</th>
                      <th scope="col">Post link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fbDetails?.map((post, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <img
                            style={{ width: "100px" }}
                            src={post.full_picture}
                            alt=""
                          />
                        </td>
                        <td>{post.likes ? post.likes.data.length : '-'}</td>
                        <td>{formatDate(post.created_time)}</td>
                        <td>
                          <a href={post.permalink_url}>{post.permalink_url}</a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
